import { groupBy } from 'lodash';
import { Injectable, Signal, computed, signal } from '@angular/core';
import { collectionData, documentId, Firestore, and, collection, getDocs, getFirestore, query, where, updateDoc, orderBy } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { Organisation } from './models/organisation';
import { map } from 'rxjs';
import { nanoid } from 'nanoid';
import { Timestamp, doc, setDoc } from 'firebase/firestore';
import { Auth, User, authState } from '@angular/fire/auth';
import { Time } from '@angular/common';
import { Functions } from '@angular/fire/functions';
import { httpsCallable } from 'firebase/functions';
import { deleteObject, getBlob, getStorage, ref, uploadBytes } from '@angular/fire/storage';


@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(public afs: Firestore, // Inject Firestore service
    private authService: AuthService,
    private afAuth: Auth,
    private functions: Functions) {

    console.log("Org service constructed");

    authState(this.afAuth)
      .subscribe((user: User) => {
        if (user) {
          user.getIdTokenResult().then(token => {
            let orgClaim = token.claims['organisationId'] as string;
            if (orgClaim) {
              this.initializeOrganisation(orgClaim, user);
            }
          });
        }
        // this.UserOrganisations().subscribe(orgs => {
        //   if (orgs.length) {

        //     const orgClaimsCallable = httpsCallable(this.functions, "setOrganisationClaims");

        //     orgClaimsCallable({ organisationId: orgs[0].uid, email: user.email });
        //   }
        // })
      })
  }

  private _organisations = signal<Organisation[]>(null);
  organisations = this._organisations.asReadonly();

  private _organisation = signal<Organisation>(null);
  organisation = this._organisation.asReadonly();



  public async focusOrganisation(organisationId: string): Promise<void> {

    var promise = new Promise<void>((resolve, reject) => {

      authState(this.afAuth)
        .subscribe(async (user: User) => {
          if (user) {
            const orgClaimsCallable = httpsCallable(this.functions, "setOrganisationClaims");
            console.log(this.functions);

            const result = await orgClaimsCallable({ organisationId: organisationId, email: user.email });

            if (result.data == "Ok") {
              user.getIdToken(true);
              this.initializeOrganisation(organisationId, user)
              resolve();
            }
          }
        });
    });

    return promise;

  }

  public initializeOrganisations() {


    var defaultDb = getFirestore();//default

    const orgsQuery = query(collection(defaultDb, "organisations"));//Orders by the numerical doc id and gets top 1

    const orgsSnapshot = getDocs(orgsQuery);

    orgsSnapshot.then(o => {

      const organisations = o.docs
        .map(m => {

          let orgData = m.data();

          return Organisation.Hydrate(
            m.id,
            orgData['name'],
            orgData['postcode'],
            orgData['logoPath'],
            orgData['administratorEmails'],
          );
        });

      this._organisations.set(organisations)
    });

  }

  private initializeOrganisation(organisationId: string, user: any) {

    if (organisationId) {

      const orgsQuery = query(collection(this.afs, "organisations"), and(where(documentId(), "==", organisationId)));//Orders by the numerical doc id and gets top 1

      const orgsSnapshot = getDocs(orgsQuery);

      orgsSnapshot.then(o => {

        const organisations = o.docs
          .map(m => {

            let orgData = m.data();

            return {
              uid: m.id,
              name: orgData['name'],
              postcode: orgData['postcode'],
              logoPath: orgData['logoPath'],
              administratorEmails: orgData['administratorEmails']
            } as Organisation;
          });

        this._organisation.set(organisations[0])
      });

      // //Init campaigns
      // this.initEventCampaigns(organisationId);

      // //Init events
      // this.initEvents(organisationId);

      // // //Init tournaments
      // this.initTournaments(organisationId);

      // // //Init campaign reviews
      // this.initCampaignReviews(organisationId);
    }
  }



  async CreateOrganisation(name: string, tempLogoPath: string, postcode: string): Promise<void> {

    var createOrgPromise = new Promise<void>(async (resolve, reject) => {

      const loggedInUser = this.authService.loggedInUser();

      const orgsReference = collection(this.afs, "organisations");
      const orgsQuery = query(orgsReference, and(where('name', "==", name)));//Orders by the numerical doc id and gets top 1

      const orgsSnapshot = await getDocs(orgsQuery);

      if (orgsSnapshot.docs.length > 0)
        throw new Error("Duplicate Organisation");

      const orgToSave = Organisation.Create(name, postcode, [loggedInUser.email]);

      var saveLogoPromise = new Promise<string | null>((resolve, reject) => {

        if (tempLogoPath) {
          //copy to new location
          const storage = getStorage();

          const tempLogoRef = ref(storage, tempLogoPath);

          const tempLogoPathParts = tempLogoPath.split("/");

          const organisationLogoPath = 'images/organisation-logos/' + orgToSave.uid + '/' + (tempLogoPathParts[tempLogoPathParts.length - 1]);
          const logoRef = ref(storage, organisationLogoPath);

          getBlob(tempLogoRef)
            .then((b) => {
              uploadBytes(logoRef, b)
                .catch((error) => {
                  console.error('Upload organisation logo error');
                  console.error(error);
                });

              //Need to delete the templogo folder, not just the file
              const tempLogoFolderPath = tempLogoPathParts.slice(0, tempLogoPathParts.length - 1).join('/');
              deleteObject(ref(storage, tempLogoFolderPath));
              resolve(organisationLogoPath);

            }).catch((error) => {
              console.error('Download organisation temp logo error');
              console.error(error);
            });
        }
        else {
          resolve(null);
        }
      });

      saveLogoPromise.then(path => {

        if (path) {
          orgToSave.SetLogoPath(path);
        }

        setDoc(doc(this.afs, 'organisations', nanoid()), orgToSave.ToPoco()).then(() => {
          this.focusOrganisation(orgToSave.uid);
          resolve();
        });
      });

    });

    return createOrgPromise;
  }

  async EditOrganisation(name: string, postcode: string): Promise<void> {

    var promise = new Promise<void>((resolve, reject) => {

      authState(this.afAuth)
        .subscribe(async (user: User) => {
          if (user) {
            user.getIdTokenResult().then(async token => {
              let orgClaim = token.claims['organisationId'] as string;

              const orgsReference = collection(this.afs, "organisations");
              const orgsQuery = query(orgsReference, and(where(documentId(), "==", orgClaim), where("administratorEmails", 'array-contains', user.email)));//Orders by the numerical doc id and gets top 1


              let organisationsPromise = getDocs(orgsQuery);

              return organisationsPromise.then(async e => {

                let orgDoc = e.docs[0];
                let orgData = e.docs[0].data();

                const organisation = Organisation.Hydrate(
                  orgDoc.id,
                  orgData['name'],
                  orgData['postcode'],
                  orgData['logoPath'],
                  orgData['administratorEmails'],
                );

                organisation.SetName(name);
                organisation.SetPostcode(postcode);

                let organisationDoc = doc(this.afs, "organisations", orgDoc.id);

                updateDoc(organisationDoc, {
                  name: organisation.name,
                  postcode: organisation.postcode
                }).then(() => {
                  this.initializeOrganisation(orgDoc.id, user);
                  resolve();
                });
              });
            });
          }
        });
    });

    return promise;
  }

  async UploadPoster(campaignUid: string, fileRef: string): Promise<any> {
    console.log("Upload Poster");
    const eventsQuery = query(collection(this.afs, "event-campaigns"), where(documentId(), "==", campaignUid));

    let eventsPromise = getDocs(eventsQuery);

    return eventsPromise.then(async e => {

      let eventDoc = doc(this.afs, "event-campaigns", e.docs[0].id);

      return updateDoc(eventDoc, {
        posterPath: fileRef
      });
    })
  }


  async UploadOrganisationLogo(organisationId: string, fileRef: string): Promise<any> {
    console.log("Upload Logo");
    const organisationsQuery = query(collection(this.afs, "organisations"), where(documentId(), "==", organisationId));

    let organisationsPromise = getDocs(organisationsQuery);

    return organisationsPromise.then(async e => {

      let organisationDoc = doc(this.afs, "organisations", e.docs[0].id);

      return updateDoc(organisationDoc, {
        logoPath: fileRef
      });
    })
  }


}
