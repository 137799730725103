export const environment = {
    production: true,
    useHostingEmulator: false,
    useStorageEmulator: false,
    useAuthEmulator:false,
    useFunctionEmulator: false,
    firebase: {
        apiKey: "AIzaSyC3oG4xgfNFV-30-eSOByt9YfsUe7S8wY0",
        authDomain: "solid-scheme-383010.firebaseapp.com",
        projectId: "solid-scheme-383010",
        storageBucket: "grts-website-dev",
        messagingSenderId: "44373413829",
        appId: "1:44373413829:web:768e56d1870e72cbfc05ac",
        measurementId: "G-GD8627T5GY",
        mapsApiKey: "AIzaSyD-41BGujGvLBq8AKx-nVqpZB5AKUVA0rU",
        databaseName: "(default)",
        region: "europe-west2"       
    },
    reCAPTCHASiteKey:"6LdajJEqAAAAAJ0Fn1ijF0RsQYhHuq_RiCkacnEf",
    appCheckDebugToken: false,
    portalDomain: "https://account-dev.grassrootstournaments.uk",
};
