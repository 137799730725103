<header class="header navbar-area position-relative">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 mt-5">
                <img class="mx-auto d-flex brand-logo" src="/assets/logos/grassroots-tournaments-logo.svg" />
            </div>
        </div>
    </div>
</header>
<div class="display-container position-relative">
    <router-outlet></router-outlet>
</div>

<!-- <script src="/src/assets/js/interaction.js"></script> -->