@switch (pageLayoutService.layout$ | async) {  
    @case (PageLayout.Nav) {
        <app-nav-layout>
            <router-outlet />
        </app-nav-layout>
    }

    @case (PageLayout.NoNav) {
        <app-nonav-layout>
            <router-outlet />
        </app-nonav-layout>
    }    
}
