import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Firestore, collection, doc, getDoc, where, orderBy, query, documentId, getDocs, limit, getFirestore, updateDoc } from '@angular/fire/firestore';
import { getApp } from '@angular/fire/app';
import { setDoc } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(public afs: Firestore, private authService: AuthService) { } 

  async AgreeTerms(version: number, localMarketing: boolean, thirdPartyMarketing: boolean): Promise<any> {

    //Update the user record with marketing pref selections, and also the current terms version number
    const loggedInUser = this.authService.loggedInUser();

    const usersQuery = query(collection(this.afs, "users"), where(documentId(), '==', loggedInUser.uid));//Orders by the numerical doc id and gets top 1

    const usersSnapshot = await getDocs(usersQuery);

    let user;

    if (usersSnapshot.docs.length) {
      const userData = usersSnapshot.docs[0].data();

      user = {
        email: userData['email'],
        emailVerified: userData['emailVerified'],
        agreedTerms: userData['terms']
      };
    }

    const agreedTerms = {
      version: version,
      localMarketing: localMarketing,
      thirdPartyMarketing: thirdPartyMarketing,
      agreedOn: new Date()
    };

    //Update with existing or new user
    const docRef = doc(this.afs, 'users', loggedInUser.uid)

    if (!user) {
      user = {
        displayName: loggedInUser.displayName,
        email: loggedInUser.email,
        emailVerified: loggedInUser.emailVerified,
        agreedTerms: agreedTerms
      }
      return setDoc(docRef, user);
    }
    else {
      return updateDoc(docRef, {
        displayName: loggedInUser.displayName,
        email: loggedInUser.email,
        emailVerified: loggedInUser.emailVerified,
        agreedTerms: agreedTerms
      });
    }
  }

  async GetFocusedOrganisation(): Promise<any>
  {
    const loggedInUser = this.authService.loggedInUser();

    const usersQuery = query(collection(this.afs, "users"), where(documentId(), '==', loggedInUser.uid));//Orders by the numerical doc id and gets top 1

    const usersSnapshot = await getDocs(usersQuery);

    if (usersSnapshot.docs.length) {
      const userData = usersSnapshot.docs[0].data();
      return userData['selectedOrganisationId'];
    }
  }

  async SaveFocusedOrganisation(organisationId: string): Promise<any>
  {
    const loggedInUser = this.authService.loggedInUser;

    const docRef = doc(this.afs, 'users', loggedInUser().uid)

    return updateDoc(docRef, {
      selectedOrganisationId: organisationId
    });
  }


  async GetTerms(): Promise<any> {

    // return {
    //   version: 1,
    //   html: "Hello World, nice to meet you"
    // };

    const termsCollectionQuery = query(collection(this.afs, "terms"), orderBy('version', 'desc'), limit(1));//Orders by the numerical doc id and gets top 1

    const querySnapshot = await getDocs(termsCollectionQuery);

    if (querySnapshot.docs) {

      const termsData = querySnapshot.docs[0].data();

      console.log("Document data:", termsData);

      return {
        version: termsData['version'],
        html: termsData['html']
      };


    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }


  }


}
