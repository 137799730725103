import { PageLayoutService } from './shared/services/page-layout.service';
import { OrganisationService } from './shared/services/organisation.service';
import { AuthService } from './shared/services/auth.service';
import {
  Router, Event as RouterEvent, NavigationStart,
  RouterOutlet,
  RouterModule
} from '@angular/router';
import { Component, Signal, computed, signal } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import init from 'src/assets/js/main.js';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRightToBracket, faUser, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { User, authState } from '@angular/fire/auth';
import { NavLayoutComponent } from './layouts/nav-layout/nav-layout.component';
import { NonavLayoutComponent } from './layouts/nonav-layout/nonav-layout.component';
import { PageLayout } from './shared/enum/page-layout.enum';

//declare function init(): void;

@Component({
  standalone: true,
  imports: [RouterOutlet, NgIf, FontAwesomeModule, RouterModule, CommonModule, NavLayoutComponent, NonavLayoutComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'account';

  PageLayout = PageLayout;

  faArrowRightToBracket = faArrowRightToBracket;
  faUser = faUser;
  faRightFromBracket = faRightFromBracket;

  loggedInUser: Signal<User>;
  // @ViewChild('preLoader') preloader: HTMLElement;

  //loading = true;

  constructor(private router: Router, private authService: AuthService, public orgService: OrganisationService, protected pageLayoutService:PageLayoutService) {

    this.loggedInUser = computed(() => {

      var loggedInUser = this.authService.loggedInUser();

      return loggedInUser;

    });

    // this.router.events.subscribe((e: RouterEvent) => {
    //   this.navigationInterceptor(e);
    // })

    //init();
  }

  // async ngAfterViewInit(){
  //   console.log("AfterViewInit");
  //   this.router.events.subscribe((e: RouterEvent) => {
  //     this.navigationInterceptor(e);
  //   })
  // }

  // navigationInterceptor(event: RouterEvent): void {
  //   if (event instanceof NavigationStart) {
  //     this.loading = true
  //   }

  //   if (event instanceof NavigationEnd) {
  //     window.setTimeout(() => {
  //       this.loading = false;
  //     }, 500);
  //   }

  //   // Set loading state to false in both of the below events to hide the spinner in case a request fails
  //   if (event instanceof NavigationCancel) {
  //     this.loading = false
  //   }
  //   if (event instanceof NavigationError) {
  //     this.loading = false
  //   }
  // }

  ngAfterViewInit() {
    init();
  }

  signOut(){
    this.authService.SignOut();
  }

  // fadeout() {
  //   this.preloader.style.opacity = '0';
  //   this.preloader.style.display = 'none';
  // }

  // fadein() {
  //   this.preloader.style.opacity = '100';
  //   this.preloader.style.display = 'block';
  // }
}
