import { getAuth } from 'firebase/auth';
import { CSP_NONCE, importProvidersFrom } from '@angular/core';

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { RouterModule, withDebugTracing } from '@angular/router';
import { HttpClientModule, provideHttpClient, withJsonpSupport } from '@angular/common/http';
import { MAIN_ROUTES } from './main.routes';
import { AuthService } from './app/shared/services/auth.service';

import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { Firestore, connectFirestoreEmulator, getFirestore, initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { AppCheck, ReCaptchaEnterpriseProvider, ReCaptchaV3Provider, initializeAppCheck, provideAppCheck } from '@angular/fire/app-check';

import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';

import { connectAuthEmulator, provideAuth } from '@angular/fire/auth';
import { environment } from './environments/environment';
import { UserService } from './app/shared/services/user.service';

if (!environment.production) {
  console.log("Adding firebase AppCheck debug token");
  (<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN = environment.appCheckDebugToken;
}
else{
  (<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN = false;
}

bootstrapApplication(AppComponent,
  {
    providers: [
      // {
      //   provide: CSP_NONCE,
      //   useValue: 'random_nonce_value'
      // },
      importProvidersFrom(RouterModule.forRoot(MAIN_ROUTES)),//, { enableTracing: true })),
      importProvidersFrom(HttpClientModule),
      importProvidersFrom(AuthService, UserService),
      importProvidersFrom(provideFirebaseApp(() => initializeApp(environment.firebase))),
      importProvidersFrom(provideAppCheck(() => {

        let appCheck: AppCheck;

        // if (!environment.useHostingEmulator) {
        //   console.log("Init appcheck");
        //   console.log(environment.reCAPTCHASiteKey);
        //   appCheck = initializeAppCheck(getApp(), {
        //     provider: new ReCaptchaV3Provider(environment.reCAPTCHASiteKey),
        //     isTokenAutoRefreshEnabled: true
        //   })
        // }

        return appCheck;
      })),
      importProvidersFrom(provideFirestore(() => {
        let firestore: Firestore;
        if (environment.useStorageEmulator) {
          firestore = initializeFirestore(getApp(), {
            experimentalForceLongPolling: true
          });
          connectFirestoreEmulator(firestore, 'localhost', 8080);
        }
        else {
          firestore = initializeFirestore(getApp(), {
            experimentalForceLongPolling: false
          }, 'account');
        }
        return firestore;
      })),
      importProvidersFrom(provideStorage(() => {
        const storage = getStorage(getApp());
        if (environment.useStorageEmulator) {
          connectStorageEmulator(storage, 'localhost', 9199);
        }
        return storage;
      })),
      importProvidersFrom(provideFunctions(() => {
        const funcs = getFunctions(getApp(), environment.firebase.region);
        if (environment.useFunctionEmulator) {
          connectFunctionsEmulator(funcs, 'localhost', 5001);
        }
        return funcs;
      })),
      provideHttpClient(withJsonpSupport()),
      importProvidersFrom(provideAuth(() => {
        const auth = getAuth(getApp());
        if (environment.useAuthEmulator) {
          connectAuthEmulator(auth, 'http://localhost:9099', {
            disableWarnings: true
          });
        }
        return auth;
      }))
    ]
  }
).catch(err => console.error(err));