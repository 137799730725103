import { environment } from './../../../environments/environment';
import { AuthService } from './../../shared/services/auth.service';
import { CommonModule } from '@angular/common';
import { Component, computed, Signal, signal } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Router, RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRightToBracket, faRightFromBracket, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nav-layout',
  standalone: true,
  imports: [RouterOutlet, FontAwesomeModule, CommonModule],
  templateUrl: './nav-layout.component.html',
  styleUrl: './nav-layout.component.scss'
})
export class NavLayoutComponent {

  faUserPlus = faUserPlus;
  faArrowRightToBracket = faArrowRightToBracket;
  faUser = faUser;
  faRightFromBracket = faRightFromBracket;

  portalDomain: string = environment.portalDomain;

  navLayoutData: Signal<NavLayoutComponentData | null> = signal(null);

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {

    this.navLayoutData = computed(() => {
      var loadingAuthState = this.authService.loadingAuthState();
      var loggedInUser = this.authService.loggedInUser();

      return {
        loggedInUser: loggedInUser,
        loadingAuthState: loadingAuthState
      };
    })
  }

  signOut() {
    this.authService.SignOut().then(()=>{
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/'])});
    });
  }
}



interface NavLayoutComponentData {
  loggedInUser: User;
  loadingAuthState: boolean;
}