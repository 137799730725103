import { Router } from '@angular/router';

import { Auth, AuthProvider, GoogleAuthProvider, OAuthProvider, authState, signInWithCustomToken, unlink } from '@angular/fire/auth';
import { Firestore, doc, setDoc } from '@angular/fire/firestore';
import { User, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, updateProfile, user } from '@angular/fire/auth';
import { Injectable, signal } from '@angular/core';
import { IdTokenResult, ParsedToken, UserCredential, linkWithCredential } from 'firebase/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Observable, from, map, mergeMap } from 'rxjs';

import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data
  //private afAuth: Inject(Auth);

  constructor(
    public afs: Firestore, // Inject Firestore service
    private afAuth: Auth, // Inject Firebase auth service
    public router: Router,
    private functions: Functions
  ) {


    /* Saving user data in localstorage when T
    logged in and setting up null when logged out */
    this._loadingAuthState.set(true);

    authState(this.afAuth).subscribe((user) => {
      if (user) {
        this._loggedInUser.set(user);
      }
      this._loadingAuthState.set(false);
    });
  }

  private _loadingAuthState = signal<boolean>(null);
  loadingAuthState = this._loadingAuthState.asReadonly();

  private _loggedInUser = signal<User>(null);
  loggedInUser = this._loggedInUser.asReadonly();


  authState(): Observable<User> {
    return authState(this.afAuth);
  }

  get isAuthenticated(): boolean {
    return this.afAuth.currentUser != null;
  };

  get identityUser(): Observable<IdTokenResult> {

    return authState(this.afAuth).pipe(
      mergeMap(user => from(user.getIdTokenResult())),
      map(idToken => {
        return idToken;
      }));
  };

  get userClaims(): Observable<ParsedToken> {

    return authState(this.afAuth).pipe(
      mergeMap(user => from(user.getIdTokenResult())),
      map(idToken => {
        return idToken['claims'];
      }));
  };

  CurrentUser(): Observable<any> {
    return authState(this.afAuth);
  }

  // Sign in with email/password
  async SignIn(email: string, password: string): Promise<void> {

    var responsePromise = new Promise<void>((resolve, reject) => {

      signInWithEmailAndPassword(this.afAuth, email, password)
        .then(async (result: UserCredential) => {

          return result.user.getIdToken().then(idToken => {

            const sessionLoginCallable = httpsCallable(this.functions, 'sessionlogin', {});
            const csrfToken = this.GetCookie('csfrToken');
            sessionLoginCallable.call({ "idToken": idToken }, { 'csfrToken': csrfToken })

            // this.SetUserData(result.user);
            // authState(this.afAuth).subscribe(async (user: any) => {
            //   if (user) {
            //     if (resolve) {
            //       const setSystemClaimsCallable = httpsCallable(this.functions, "setSystemClaims");
            //       await setSystemClaimsCallable({ email: user.email });

            //       this.SetAuthCookie(user.accessToken);

            //       resolve();
            //     }
            //     else {
            //       this.router.navigate(['dashboard']);
            //     }
            //   }
            // });


          })



        })
        .catch((error) => {
          window.alert(error.message);
          reject();
        });
    });

    return responsePromise;
  }



  // // Sign in with email/password
  // async SignIn(email: string, password: string): Promise<void> {

  //   var responsePromise = new Promise<void>((resolve, reject) => {

  //     signInWithEmailAndPassword(this.afAuth, email, password)
  //       .then(async (result) => {
  //         this.SetUserData(result.user);
  //         authState(this.afAuth).subscribe(async (user: any) => {
  //           if (user) {
  //             if (resolve) {
  //               const setSystemClaimsCallable = httpsCallable(this.functions, "setSystemClaims");
  //               await setSystemClaimsCallable({ email: user.email });

  //               this.SetAuthCookie(user.accessToken);

  //               resolve();
  //             }
  //             else {
  //               this.router.navigate(['dashboard']);
  //             }
  //           }
  //         });
  //       })
  //       .catch((error) => {
  //         window.alert(error.message);
  //         reject();
  //       });
  //   });

  //   return responsePromise;
  // }

  async GoogleAuth(): Promise<void> {
    var provider = new GoogleAuthProvider();
    provider.setDefaultLanguage('en');
    return await this.SocialSignIn(provider);
  }

  async MicrosoftAuth(): Promise<void> {
    var provider = new OAuthProvider('microsoft.com');
    provider.setDefaultLanguage('en');

    return await this.SocialSignIn(provider);
  }


  private async SignInWithCustomToken() {

    const customToken = this.GetCookie('firebaseCustomToken'); // Get the stored token
    if (customToken) {
      signInWithCustomToken(this.afAuth, customToken)
        .then((userCredential) => {
          // User is signed in
        })
        .catch((error) => {
          // Handle errors
        });
    }
  }


  private SetAuthCookie(accessToken: string) {
    document.cookie = `firebaseCustomToken=${accessToken}; HttpOnly; Secure=true; Domain=null`;
  }

  private GetCookie(name: string): any {
    return document[name];
  }



  // Sign in with social provider
  private async SocialSignIn(provider: AuthProvider): Promise<void> {

    var responsePromise = new Promise<void>((resolve, reject) => {
      signInWithPopup(this.afAuth, provider)
        .then(async (result: UserCredential) => {

          return result.user.getIdToken().then(idToken => {

            //const csrfToken = this.GetCookie('csfrToken');

            //var functionBaseUrl = 'https://europe-west2-solid-scheme-383010.cloudfunctions.net';
            //var functionBaseUrl = 'https://account-dev.grassrootstournaments.uk';
            //var functionBaseUrl = 'http://127.0.0.1:5001/solid-scheme-383010/europe-west2'; //Local
            var functionBaseUrl = 'https://functions-dev.grassrootstournaments.uk'; //Hosted

            var body = {
              idToken: idToken
            };

            axios.post(functionBaseUrl + '/sessionLogin', body, {
              withCredentials: true
            }).then(function (response) {
              console.log(response);

              axios.get(functionBaseUrl + '/checkAuthStatus', {
                withCredentials: true
              })
                .then(function (response) {
                  console.log(response);
                })
                .catch(function (error) {
                  console.error(error);
                });
            })
              .catch(function (error) {
                console.error(error);
              });

            // console.log(result);
            // this.SetUserData(result.user);
            // this.afAuth.onAuthStateChanged(async (user: any) => {
            //   if (user) {
            //     const setSystemClaimsCallable = httpsCallable(this.functions, "setSystemClaims");
            //     await setSystemClaimsCallable({ email: user.email });

            //     this.SetAuthCookie(user.accessToken);

            //     resolve();
            //   }
            // });

          });

        })
        .catch((error) => {
          console.error(error);

          if (error.code === 'auth/account-exists-with-different-credential') {

            //At this point, if the user is not logged in then we need to prompt them to log in using an existing 
            //authentication provider. If we can get a list on valid ones then it would be helpful to redirect to
            //next screen. From there we can get them to log in and then link

            const credential = OAuthProvider.credentialFromError(error);

            console.error(credential);

            linkWithCredential(this.afAuth.currentUser, credential);
          }

        });
    });

    return responsePromise;

  }

  UnlinkAccount(providerId: string) {
    unlink(this.userData, providerId);
  }

  // Sign up with email/password
  SignUp(email: string, password: string) {
    createUserWithEmailAndPassword(this.afAuth, email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        // updateProfile(result.user, {
        //   displayName: displayName
        // });

        this.SendVerificationMail();
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return user(this.afAuth)
      .subscribe((u: User) => {
        sendEmailVerification(u);
        this.router.navigate(['verify-email-address']);
      })
    // .then(() => {
    //   this.router.navigate(['verify-email-address']);
    // });
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    sendPasswordResetEmail(this.afAuth, passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in and email is verified
  // get isLoggedIn(): boolean {
  //   const user = JSON.parse(localStorage.getItem('user')!);
  //   return user !== null && user.emailVerified !== false ? true : false;
  // }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    const userRef = doc(this.afs, `users/${user.uid}`)

    const userData: any = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return setDoc(userRef, userData, {
      merge: true,
    });
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['/account/sign-in']);
    });
  }
}