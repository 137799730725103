import { nanoid } from "nanoid";

export class Organisation {
    uid: string;
    name: string;
    postcode: string;
    logoPath: string;
    administratorEmails: string[];

    constructor(uid: string, name: string, postcode: string, logoPath: string, administratorEmails: string[]) {
        this.uid = uid;
        this.name = name;
        this.postcode = postcode;
        this.logoPath = logoPath;
        this.administratorEmails = administratorEmails;
    }

    public static Create(name: string, postcode: string, administratorEmails: string[]): Organisation {
        return new Organisation(nanoid(), name, postcode, null, administratorEmails);
    }

    public static Hydrate(uid: string, name: string, postcode: string, logoPath: string, administratorEmails: string[]): Organisation {
        return new Organisation(uid, name, postcode, logoPath, administratorEmails);
    }

    public SetLogoPath(logoPath: string): void {
        this.logoPath = logoPath;
    }

    public SetName(name: string) {
        this.name = name;
    }
    public SetPostcode(postcode: string) {
        this.postcode = postcode;
    }

    public ToPoco() {
        return {
            uid: this.uid,
            name: this.name,
            logoPath: this.logoPath,
            postcode: this.postcode,
            administratorEmails: this.administratorEmails
        }
    }
}