<header class="header navbar-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="nav-inner">
                    <!-- Start Navbar -->
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" href="/">
                            <img src="assets/logos/grassroots-tournaments-logo-whiteblack.png" alt="Logo">
                        </a>
                        <button class="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="toggler-icon"></span>
                            <span class="toggler-icon"></span>
                            <span class="toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                            <ul class="navbar-nav auth-nav">
                                <li class="nav-item mx-0 mx-lg-1">
                                    @if(navLayoutData(); as navLayoutData){
                                    @if(!navLayoutData.loadingAuthState){

                                    @if(!navLayoutData.loggedInUser){
                                    <a class="nav-link py-3 px-0 pe-lg-1 ps-lg-3 rounded d-inline" href="/account/register">
                                        <fa-icon [icon]="faUserPlus"></fa-icon> Register
                                    </a>
                                    <a class="nav-link py-3 px-0 ps-lg-1 pe-lg-3 rounded d-inline" href="/account/sign-in">
                                        <fa-icon [icon]="faArrowRightToBracket"></fa-icon> Sign In
                                    </a>
                                    }
                                    @if(navLayoutData.loggedInUser; as user){
                                    <a class="btn me-2 float-start" target="_blank"
                                        [href]="portalDomain + '/organisation'">
                                        <fa-icon [icon]="faUser"></fa-icon>&nbsp;Management Portal
                                    </a>
                                    <a (click)="signOut()" href="#" class="float-end ">
                                        <fa-icon [icon]="faRightFromBracket"></fa-icon> Sign Out
                                    </a>
                                    }
                                    }
                                    }
                                </li>
                            </ul>
                            <!-- </div> -->
                        </div>
                        <!-- <div class="button add-list-button">
                            <a href="javascript:void(0)" class="btn">Get it now</a>
                        </div> -->
                    </nav>




                </div>
            </div>
        </div>
    </div>
</header>
<!-- 
<div class="preloader">
    <div class="preloader-inner">
        <div class="preloader-icon">
            <span></span>
            <span></span>
        </div>
    </div>
</div> -->

<router-outlet></router-outlet>

<script src="/src/assets/js/interaction.js"></script>